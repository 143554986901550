var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c_blog_press_releases c_text--right"},[(_vm.header)?_c('h3',{staticClass:"c_blog_press_releases__header c_header c_header--6 c_text--graphik_bold c_text--left",domProps:{"innerHTML":_vm._s(_vm.header)}}):_vm._e(),_vm._v(" "),(_vm.hasOverrideFeed)?_c('Grid',{staticClass:"c_blog_press_releases__cards",attrs:{"columns":_vm.$mq == 'md' ? 3 : _vm.$mq == 'lg' ? 3 : 1,"gap":"15px"}},[_vm._l((_vm.overrideFeed.slice(0, _vm.$mq == 'md' ? 3 : 3)),function(post,index){return [(post.image?.[0] && post.eventOverviewLinks)?_c('Card',{key:index,attrs:{"title":post.title,"date":post.eyebrowDate,"description":post.summary,"aria-label":post.title,"image-full-width":true,"show-text-button":true,"links":post.eventOverviewLinks,"image-options":{
          alt: post.image?.[0].title,
          sources: [
            { srcset: post.image?.[0]?.images?.srcsetWebp, type: 'webp' },
            { srcset: post.image?.[0]?.images?.srcset },
          ],
        }}}):(post.image?.[0])?_c('Card',{key:index + 3,attrs:{"title":post.title,"date":post.eyebrowDate,"description":post.summary,"href":post.uri,"aria-label":post.title,"image-full-width":true,"image-options":{
          alt: post.image?.[0].title,
          sources: [
            { srcset: post.image?.[0]?.images?.srcsetWebp, type: 'webp' },
            { srcset: post.image?.[0]?.images?.srcset },
          ],
        }}}):_vm._e()]}),_vm._v(" "),_vm._l((_vm.overrideFeed.slice(0, _vm.$mq == 'md' ? 3 : 3)),function(post,index){return (!post.image?.[0])?_c('div',{key:index,staticStyle:{"text-align":"center"}},[_c('div',[_c('h1',[_c('strong',[_vm._v("Error")])]),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',[_c('strong',[_vm._v("\""+_vm._s(post.title)+"\"")]),_vm._v(" is missing a featured image needed to render this card, please fix in\n          the CMS.\n        ")])])]):_vm._e()})],2):(_vm.blogPressEntries)?_c('Grid',{staticClass:"c_blog_press_releases__cards",attrs:{"columns":_vm.$mq == 'md' ? 3 : _vm.$mq == 'lg' ? 3 : 1,"gap":"15px"}},_vm._l((_vm.blogPressEntries.slice(0, _vm.$mq == 'md' ? 3 : 3)),function(post,index){return (post.blogFeaturedImage?.[0])?_c('Card',{key:index,attrs:{"title":post.title,"date":post.postDate,"description":post.articleSummary,"href":post.uri,"aria-label":post.title,"image-full-width":true,"image-options":{
        alt: post.blogFeaturedImage?.[0].title,
        sources: [
          { srcset: post.blogFeaturedImage?.[0]?.relatedEntries?.srcsetWebp, type: 'webp' },
          { srcset: post.blogFeaturedImage?.[0]?.relatedEntries?.srcset },
        ],
      }}}):_vm._e()}),1):_vm._e(),_vm._v(" "),(_vm.buttonLinkFieldParsed && _vm.parseLinkField(_vm.buttonLinkFieldParsed, 'superTable').valid)?_c('LinkField',{staticClass:"c_blog_press_releases__link",attrs:{"component-type":"arrow","super-table-field":_vm.buttonLinkFieldParsed}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }