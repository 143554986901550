// @TODO Add fields for press release entries
export const relatedBlogAndPressReleasesGql = `query($limit: Int, $relations: [QueryArgument], $sections: [String], $site: [String]) {
  entries(
    limit: $limit,
    section: $sections,
    relatedTo: $relations,
    site: $site,
    orderBy: "postDate DESC",
  ){
    title
    postDate @formatDateTime(format: "F j, Y")
    uri
    id
    ... on blog_blog_Entry {
      articleSummary
      blogFeaturedImage {
        ... on blogNews_Asset {
          title
          relatedEntries {
            srcset
            srcsetWebp
          }
        }
      }
    }
    ... on pressRelease_pressRelease_Entry{
      articleSummary
      blogFeaturedImage{
        ...on blogNews_Asset{
          title
          relatedEntries{
            srcset
            srcsetWebp
          }
        }
      }
    }
  }
}`;
