<template>
  <div class="c_blog_press_releases c_text--right">
    <h3
      v-if="header"
      v-html="header"
      class="c_blog_press_releases__header c_header c_header--6 c_text--graphik_bold c_text--left"
    ></h3>
    <Grid
      :columns="$mq == 'md' ? 3 : $mq == 'lg' ? 3 : 1"
      gap="15px"
      class="c_blog_press_releases__cards"
      v-if="hasOverrideFeed"
    >
      <template v-for="(post, index) in overrideFeed.slice(0, $mq == 'md' ? 3 : 3)">
        <Card
          v-if="post.image?.[0] && post.eventOverviewLinks"
          :key="index"
          :title="post.title"
          :date="post.eyebrowDate"
          :description="post.summary"
          :aria-label="post.title"
          :image-full-width="true"
          :show-text-button="true"
          :links="post.eventOverviewLinks"
          :image-options="{
            alt: post.image?.[0].title,
            sources: [
              { srcset: post.image?.[0]?.images?.srcsetWebp, type: 'webp' },
              { srcset: post.image?.[0]?.images?.srcset },
            ],
          }"
        />
        <Card
          v-else-if="post.image?.[0]"
          :key="index + 3"
          :title="post.title"
          :date="post.eyebrowDate"
          :description="post.summary"
          :href="post.uri"
          :aria-label="post.title"
          :image-full-width="true"
          :image-options="{
            alt: post.image?.[0].title,
            sources: [
              { srcset: post.image?.[0]?.images?.srcsetWebp, type: 'webp' },
              { srcset: post.image?.[0]?.images?.srcset },
            ],
          }"
        />
      </template>
      <div
        v-for="(post, index) in overrideFeed.slice(0, $mq == 'md' ? 3 : 3)"
        :key="index"
        v-if="!post.image?.[0]"
        style="text-align: center"
      >
        <div>
          <h1><strong>Error</strong></h1>
          <br />
          <p>
            <strong>"{{ post.title }}"</strong> is missing a featured image needed to render this card, please fix in
            the CMS.
          </p>
        </div>
      </div>
    </Grid>
    <Grid
      :columns="$mq == 'md' ? 3 : $mq == 'lg' ? 3 : 1"
      gap="15px"
      class="c_blog_press_releases__cards"
      v-else-if="blogPressEntries"
    >
      <Card
        v-for="(post, index) in blogPressEntries.slice(0, $mq == 'md' ? 3 : 3)"
        v-if="post.blogFeaturedImage?.[0]"
        :key="index"
        :title="post.title"
        :date="post.postDate"
        :description="post.articleSummary"
        :href="post.uri"
        :aria-label="post.title"
        :image-full-width="true"
        :image-options="{
          alt: post.blogFeaturedImage?.[0].title,
          sources: [
            { srcset: post.blogFeaturedImage?.[0]?.relatedEntries?.srcsetWebp, type: 'webp' },
            { srcset: post.blogFeaturedImage?.[0]?.relatedEntries?.srcset },
          ],
        }"
      />
    </Grid>
    <LinkField
      class="c_blog_press_releases__link"
      v-if="buttonLinkFieldParsed && parseLinkField(buttonLinkFieldParsed, 'superTable').valid"
      component-type="arrow"
      :super-table-field="buttonLinkFieldParsed"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, useContext, useFetch, useStore } from '@nuxtjs/composition-api';
import Card from 'Components/card/Card.vue';
import Grid from 'Components/grid/Grid.vue';
import { parseLinkField } from 'JS/global.js';
import { relatedBlogAndPressReleasesGql } from 'GQL/relatedBlogAndPressReleasesGql.js';
import LinkField from 'Components/link_field/LinkField.vue';

export default defineComponent({
  name: 'BlogPressReleases',
  components: {
    Card,
    Grid,
    LinkField,
  },
  props: {
    entryId: String,
    relatedIds: Array,
    header: String,
    hasOverrideFeed: {
      type: Boolean,
      default: false,
    },
    overrideFeed: Array,
    includeBlogPosts: {
      type: Boolean,
      default: true,
    },
    includePressReleases: {
      type: Boolean,
      default: true,
    },
    buttonLinkFieldParsed: {
      type: Object,
    },
  },
  setup(props) {
    const { app, $craft } = useContext();
    const store = useStore();
    const state = reactive({
      blogPressEntries: null,
      loaderClass: 'active',
    });

    if ((props.includeBlogPosts || props.includePressReleases) && props.relatedIds?.length) {
      useFetch(async () => {
        // Look at the settings for including posts and will include the option if marked true and exclude if marked false.
        const sectionArray = [];
        if (props.includeBlogPosts) {
          sectionArray.push('blog');
        }
        if (props.includePressReleases) {
          sectionArray.push('pressRelease');
        }

        // todo fold this into parent GraphQL
        const request = await $craft({
          apiLog: 'BlogPressReleases',
          query: relatedBlogAndPressReleasesGql,
          variables: {
            limit: 3,
            site: store.state.global.localeSites[app.i18n.locale].siteHandle,
            sections: sectionArray,
            relations: props.relatedIds,
          },
        });

        if (request?.data?.entries) {
          state.blogPressEntries = props.entryId
            ? request.data.entries.filter((item) => {
                return item.id !== props.entryId && item.blogFeaturedImage && item.blogFeaturedImage.length;
              })
            : request.data.entries.filter((item) => {
                return item.blogFeaturedImage && item.blogFeaturedImage.length;
              });
        }
      });
    }

    return { ...toRefs(state) };
  },
  methods: {
    parseLinkField,
  },
});
</script>

<style lang="scss">
.c_blog_press_releases {
  $self: &;
  @at-root #{$self}__header {
    margin-bottom: px_to_rem(40);
  }
  @at-root #{$self}__cards {
    margin-bottom: px_to_rem(30);
  }
  @at-root #{$self}__link {
    margin-right: 0;
    margin-left: auto;
  }
  @at-root #{$self}__loader_container {
    position: relative;
    height: 100px;
  }
  @at-root #{$self}__loader_container {
    /* For Child Loader Components */
    position: relative;
    width: 100%;
    height: 25vh;
    display: none;
  }
  @at-root #{$self}__loader_container.active {
    display: flex;
  }
}
</style>
