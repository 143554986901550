<template>
  <Button
    :is="showTextButton ? 'div' : 'Button'"
    class="c_card"
    :class="{
      'c_card--image_full_width': imageFullWidth,
      'c_button--pointer': !(showTextButton || links !== undefined),
      'c_card--mobile_disabled': disableMobileView,
    }"
    v-if="title"
    v-bind="{ href: href, ariaLabel: ariaLabel, newWindow: newWindow, reset: true, unstyle: true }"
  >
    <div v-if="imageOptions" class="c_card__image">
      <MediaImage background v-bind="modifiedImageOptions" />
    </div>
    <Flex direction="column" class="c_card__text" :class="'c_text--' + textAlignment">
      <slot>
        <h5
          v-if="title"
          class="c_card__text__title c_header c_header--3"
          :class="imageFullWidth ? 'c_text--graphik_semibold' : 'c_text--graphik_bold'"
          v-html="title"
        ></h5>
        <div v-if="description" class="c_card__text__description" v-html="description"></div>
        <p v-if="formattedPostDate" class="c_card__text__date">{{ formattedPostDate }}</p>
        <div v-if="links" class="c_card__text__buttons">
          <LinkField
            v-for="(link, linkIndex) in links"
            v-if="parseLinkField(link, 'matrix').valid"
            :key="linkIndex"
            :matrix-field="link"
            :button-attrs="{ color: 'red', unstyle: false, outline: linkIndex != 0 }"
          />
        </div>
        <Button
          v-else-if="showTextButton && href"
          class="c_card__text__button c_button--bold"
          :href="href"
          :aria-label="ariaLabel"
          :new-window="newWindow"
          color="red"
          >{{ linkLabel }}</Button
        >
      </slot>
    </Flex>
  </Button>
</template>

<script>
import Button from 'Components/button/Button.vue';
import Flex from 'Components/flex/Flex.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import MediaImage from 'Components/image/MediaImage.vue';
import { parseLinkField, translatePostDate } from 'JS/global.js';

export default {
  components: {
    Button,
    Flex,
    LinkField,
    MediaImage,
  },
  data() {
    return {};
  },
  props: {
    ariaLabel: String,
    date: String,
    description: String,
    disableMobileView: {
      type: Boolean,
      default: false,
    },
    href: String,
    imageFullWidth: { type: Boolean, default: false },
    imageOptions: Object,
    linkLabel: String,
    links: Array,
    newWindow: { type: Boolean, default: false },
    textAlignment: {
      type: String,
      default: 'left',
    },
    title: { type: String, required: true },
    showTextButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedPostDate() {
      return translatePostDate(this.date, this.$i18n?.locale);
    },
    modifiedImageOptions() {
      const returnedImageOptions = this.imageOptions;
      returnedImageOptions.background = true;
      return returnedImageOptions;
    },
  },
  methods: {
    parseLinkField,
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  transition: $transition;
}
$_margin: 14px;
.c_card {
  $self: &;
  --card-image-height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--card-image-height) auto;
  text-decoration: none;
  background-color: $color_white;
  border: 1px solid $color_gray_235;
  box-shadow: px_to_rem(0) px_to_rem(8) px_to_rem(10) $color_shadow;
  height: 100%;
  line-height: 1.4;
  @include transition(all 0.25s ease);

  @at-root #{$self}:not(#{&}--mobile_disabled) {
    @include mq_max($mq_card_mobile, $unit: 'rem') {
      grid-template-columns: px_to_rem(105) auto;
      grid-template-rows: minmax(88px + ($_margin * 2), auto);
    }
  }

  @include mq($mq_card_mobile, $unit: 'rem') {
    --card-image-height: #{px_to_rem(250)};
    grid-template-columns: 1fr;
    grid-template-rows: var(--card-image-height) auto;
    align-items: initial;
  }

  @at-root #{$self}--image_full_width {
    border: 0;
    box-shadow: px_to_rem(3) px_to_rem(3) px_to_rem(6) rgba($color_black_raw, 0.16);
  }

  @at-root #{$self}__image {
    height: var(--card-image-height);
    box-sizing: border-box;
    @at-root #{$self}:not(.c_card--mobile_disabled) & {
      @include mq_max($mq_card_mobile, $unit: 'rem') {
        padding-top: $_margin;
        padding-bottom: $_margin;
      }
    }

    @include mq($mq_card_mobile, $unit: 'rem') {
      padding: $_margin;
      padding-bottom: 0;
    }

    #{$self}--image_full_width & {
      @include mq($mq_card_mobile, $unit: 'rem') {
        padding: 0;
      }
    }
  }
  @at-root #{$self}__text {
    $text: &;
    padding: px_to_rem(14);
    justify-content: space-between;
    color: $color_black;
    @at-root .app--isEdge15orDown & {
      grid-column: 1;
      grid-row: 2;
    }

    @include mq($mq_card_mobile, $unit: 'rem') {
      padding-bottom: px_to_rem(28);
      justify-content: flex-start;
    }
    @at-root #{&}__date {
      padding-top: px_to_rem(9);
      @include font('graphik_light');
      font-size: px_to_rem(15);
      color: $color_gray_112;

      @include mq($mq_card_mobile, $unit: 'rem') {
        padding-top: 0;
        padding-bottom: px_to_rem(9);
        order: 1;
      }
    }
    @at-root #{&}__title {
      @at-root #{$self}--mobile_disabled & {
        font-size: 1.25rem;
      }
      @include mq($mq_card_mobile, $unit: 'rem') {
        order: 2;
        //padding-bottom: px_to_rem(9);
      }
      #{$self}.c_button--pointer:hover &,
      #{$self}.c_button--pointer:focus & {
        color: $color_red;
      }
    }
    @at-root #{&}__button {
      margin-top: px_to_rem(15);
      order: 3;
    }
    @at-root #{&}__buttons {
      display: flex;
      flex-direction: column;
      margin-top: px_to_rem(15);
      order: 3;
      row-gap: 1rem;
    }
    @at-root #{&}__description {
      flex: 0 1 100%;
      @include font('graphik_light');
      color: $color_black;
      display: block;
      @at-root #{$self}:not(.c_card--mobile_disabled) & {
        @include mq_max($mq_card_mobile, $unit: 'rem') {
          display: none;
        }
      }

      @include mq($mq_card_mobile, $unit: 'rem') {
        order: 3;
        display: block;
      }

      @at-root #{$text}__title + #{$text}__description {
        margin-top: 1rem;
      }
    }
  }
}
.c_card:hover {
  box-shadow: px_to_rem(1) px_to_rem(1) px_to_rem(3) rgba($color_black_raw, 0.16);
}
</style>
